import { useStaticQuery, graphql } from "gatsby"
import React, { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import VideoPlaceholder from "../images/video-placeholder.jpg"
import Layout from "../layouts/layout"

const ContactUsPage = () => {
    const data = useStaticQuery(graphql`
        query ContactUsQuery {
            videoData: contentfulLayout(contentful_id: {eq: "T3H8XFGXUuivr4KGfj91v"}) {
                hero {
                    header_title
                    hero_body {
                      hero_body
                    }
                    heroImage {
                      url
                    }
                }
            }
            contentData: contentfulLayout(contentful_id: {eq: "7anTLnejJrE2NvldhKCUyH"}) {
                hero {
                    header_title
                    hero_body {
                        hero_body
                    }
                }
            }
        }
    `)

    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @ts-ignore
            if (window.hbspt) {
                // @ts-ignore
                window.hbspt.forms.create({
                    portalId: '4293115',
                    formId: '3405659f-ee73-4367-a0eb-c206d8dc9c26',
                    target: '#hubspot-form'
                })
            }
        });
    }, []);

    const page = data.contentData
    const videoPage = data.videoData

    const heroTitle = page.hero.header_title
    const heroBody = page.hero.hero_body.hero_body
    const heroImage = videoPage.hero.heroImage.url

    return(
        <Layout>
            <Helmet>
                <title>Contact Us | O3 Edge | Retirement Plan Prospecting for Advisors</title>
                <meta name="description" content="Have questions? Our team of experts is happy to help and equip you with the right information. Contact us today." />
            </Helmet>
            <div className="section-container position-relative">
                <Container className="form-container">
                    <Row className="header-row">
                        <Col className="text-center text-white">
                            <h1>{heroTitle}</h1>
                            <h2>{heroBody}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" className="offset-lg-3">
                            <div id="hubspot-form"></div>
                        </Col>
                    </Row>
                </Container>
                <div className="background-container">
                    <video autoPlay muted loop className="video" height="100%" poster={VideoPlaceholder}>
                        <source src={heroImage} type="video/mp4" />
                    </video>
                </div>
            </div>
        </Layout>
    )
}

export default ContactUsPage